import React from 'react'
import sessionize from '../../utils/sessionize'

window.sessionize = sessionize

class SessionizeGrid extends React.Component {
  componentDidMount () {
    this.loadSessionizeContent()

    const hasReloaded = localStorage.getItem('hasReloaded')

    if (!hasReloaded) {
      localStorage.setItem('hasReloaded', 'true')
      window.location.reload()
    }
  }

  async loadSessionizeContent () {
    const elements = document.querySelectorAll('[data-sessionize-load-url]')

    for (const element of elements) {
      const file = element.getAttribute('data-sessionize-load-url')

      if (file) {
        try {
          const response = await fetch(file)
          if (!response.ok) {
            throw new Error('Error en la carga del contenido de Sessionize')
          }
          const responseText = await response.text()
          element.innerHTML = responseText
          element.removeAttribute('data-sessionize-load-url')
        } catch (error) {
          console.error(error)
        }
      }
    }
  }

  render () {
    return (
      <div>
        <style type="text/css">
          {`.sz-spinner {
            width: 40px;
            height: 40px;
            margin: 100px auto;
            background-color: #573792;
            border-radius: 100%;
            -webkit-animation: sz-scaleout 1.0s infinite ease-in-out;
            animation: sz-scaleout 1.0s infinite ease-in-out;
          }
          @-webkit-keyframes sz-scaleout {
            0% {
              -webkit-transform: scale(0);
            }
            100% {
              -webkit-transform: scale(1.0);
              opacity: 0;
            }
          }
          @keyframes sz-scaleout {
            0% {
              -webkit-transform: scale(0);
              transform: scale(0);
            }
            100% {
              -webkit-transform: scale(1.0);
              transform: scale(1.0);
              opacity: 0;
            }
          }`}
        </style>
        <div
          className="sessionize-loader"
          data-sessionize-load-url="https://sessionize.com/api/v2/9p2hve7r/view/GridSmart?under=true"
        >
          <div className="sz-spinner"></div>
        </div>
      </div>
    )
  }
}

export default SessionizeGrid
