import React from 'react'
import { Link } from 'react-router-dom'
import '../../assets/css/conf-personal.css'
import jeffBarr from '../../assets/images/jeff_barr.png'
import evandroPires from '../../assets/images/evandro-pires.jpeg'
// import edgerQuispe from '../../assets/images/edgar-quispe.png'
import viniciusSenger from '../../assets/images/vinicius-senger.jpeg'
import harryGuillermo from '../../assets/images/harry_guillermo.jpeg'
import petersonLarentis from '../../assets/images/peterson-larentis.jpg'
import arturoMartines from '../../assets/images/Arturo Martines.jpg'
import carlosZambrano from '../../assets/images/carlos_zambrano.jpg'
import marioZakorchini from '../../assets/images/Mario Zakorchini jr.jpeg'
// import marciaVillalba from '../../assets/images/mejora-marcia.png'
import carlosCortez from '../../assets/images/carlos_cortez.png'
import andreCervantes from '../../assets/images/andre_cervantes.jpg'

// import walter from '../../assets/images/walter-white.jpeg'
import banderaEstados from '../../assets/images/banderaEstados.png'
import banderaPeru from '../../assets/images/banderaPeru.png'
import banderaBrazil from '../../assets/images/Flag-Brazil.webp'
import banderaChile from '../../assets/images/baderaChile.png'
import banderaColombia from '../../assets/images/Flag_of_Colombia.svg.png'
// import banderaUruguay from '../../assets/images/banderaUruguay.png'
import SpeakerList from '../voluntarios'
// import biancaTorres from '../../assets/images/Bianca_Torres.png'
// import giomarAntaurco from '../../assets/images/giomar_antaurco.png'
// import carlosCruzado from '../../assets/images/Carlos_Cruzado.png'
// import luisGuerra from '../../assets/images/Luis_Guerra.png'
// import carlosChicata from '../../assets/images/Carlos_Chicata.png'

const guests = [
  {
    name: 'Jeff Barr',
    job: 'Vice President & Chief Evangelist at AWS',
    twitter: 'https://twitter.com/jeffbarr',
    linkedin: 'https://www.linkedin.com/in/jeffbarr/',
    photo: jeffBarr,
    flag1: banderaEstados
  },
  {
    name: 'Evandro Pires',
    job: 'CTO at Senior SA',
    twitter: '',
    linkedin: 'https://www.linkedin.com/in/epiresdasilva/',
    photo: evandroPires,
    flag1: banderaBrazil
  },
  {
    name: 'Vinicius Senger',
    job: 'Senior Developer Advocate',
    twitter: '',
    linkedin: 'https://www.linkedin.com/in/viniciussenger/',
    photo: viniciusSenger,
    flag1: banderaBrazil
  },
  {
    name: 'Harry Guillermo',
    job: 'Sr Application Architect at Amazon Web Services',
    twitter: '',
    linkedin: 'https://www.linkedin.com/in/harryguillermo/',
    photo: harryGuillermo,
    flag1: banderaPeru,
    flag2: banderaEstados
  },
  {
    name: 'Peterson Larentis',
    job: 'Sr. Specialist Solutions Architect, Serverless',
    twitter: '',
    linkedin: 'https://www.linkedin.com/in/peterson-larentis/',
    photo: petersonLarentis,
    flag1: banderaEstados
  },
  {
    name: 'Arturo Martínez ',
    job: 'Senior Partner Trainer en Amazon Web Services (AWS)',
    twitter: '',
    linkedin: 'https://www.linkedin.com/in/arturomartinezv/',
    photo: arturoMartines,
    flag1: banderaChile
  },
  {
    name: 'Mário Zakorchini Jr ',
    job: 'AWS GTM/BD Serverless & App Modernization',
    twitter: 'https://x.com/mzakorchini',
    linkedin: 'https://www.linkedin.com/in/mzakorchini/',
    photo: marioZakorchini,
    flag1: banderaBrazil
  },
  {
    name: 'Carlos Zambrano',
    job: 'Technology Leader | AWS Data Hero | Cloud Computing Expert',
    twitter: '',
    linkedin: 'https://www.linkedin.com/in/carlos-zambrano-aws',
    photo: carlosZambrano,
    flag1: banderaColombia
  }

]

const organizers = [
  {
    name: 'Carlos Cortez',
    job: 'Cloud Architect CyberCX',
    twitter: 'https://twitter.com/',
    linkedin: 'https://www.linkedin.com/in/carloscortezcloud/',
    photo: carlosCortez,
    flag: banderaPeru
  },
  {
    name: 'André Cervantes',
    job: 'Cybersecurity Officer Sr',
    twitter: 'https://twitter.com/AndreCv22',
    linkedin:
      'https://www.linkedin.com/in/andrecv22/',
    photo: andreCervantes,
    flag: banderaPeru
  }
  // {
  //   name: 'Eder Quispe',
  //   job: 'Departamento de Ingeniería PUCP',
  //   twitter: 'https://twitter.com/',
  //   linkedin: 'https://www.linkedin.com/in/eder-quispe-vilchez/',
  //   photo: edgerQuispe,
  //   flag: banderaPeru
  // },
  // {
  //   name: 'Walter Levano',
  //   job: 'La Positiva Seguros',
  //   linkedin: 'https://www.linkedin.com/in/walter-levano-mba-devops-itil-cobit-cloudf-04a27553/',
  //   photo: walter,
  //   flag: banderaPeru
  // }
]

class Speakers extends React.Component {
  render () {
    return (
      <>
        <section className="speakers-area ptb-120 pb-0" id="speakers">
          <div className="container">
            <div className="section-title">
              <h2>SPECIAL GUESTS &  KEYNOTES</h2>
              <div className="bar"></div>
            </div>
          </div>
          <div className="container">
            <div className="row m-0">
              {guests.map((guest, index) => {
                return (
                  <div key={`guest-${index}`} className="col-lg-4 col-sm-6 p-0">
                    <div className="single-speakers">
                      <img
                        src={guest.photo}
                        alt="Speaker"
                        className="photo width-images"
                      />

                      <div className="speakers-content">
                        <h3 className='row-flag'>
                          <Link to="#">{guest.name}</Link>
                          {guest.flag1 && (
                            <img
                              className='flag-size'
                              src={guest.flag1}
                              alt="Guest flag1"
                            />
                          )}
                          {guest.flag2 && (
                            <img
                              className='flag-size'
                              src={guest.flag2}
                              alt="Guest flag2"
                            />
                          )}
                        </h3>
                        <span>{guest.job}</span>
                        <ul>
                          <li>
                            <a
                              href={guest.twitter}
                              target="blank"
                              className="twitter"
                            >
                              <i className="icofont-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href={guest.linkedin}
                              target="blank"
                              className="linkedin"
                            >
                              <i className="icofont-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
        <section className="speakers-area ptb-120">
          <div className="container">
            <div className="section-title">
              <h2>ORGANIZERS & FOUNDERS</h2>
              <div className="bar"></div>
            </div>
          </div>
          <div className="container">
            <div className="row m-0">
              {organizers.map((guest, index) => {
                return (
                  <div key={`guest-${index}`} className="col-lg-4 col-sm-6 p-0">
                    <div className="single-speakers">
                      <img
                        src={guest.photo}
                        alt="Speaker"
                        className="photo width-images"
                      />

                      <div className="speakers-content">
                        <h3 className="row-flag">
                          <Link to="#">{guest.name}</Link>
                          <img
                            className="flag-size"
                            src={guest.flag}
                            alt="Guest Flag"
                          />
                        </h3>
                        <span>{guest.job}</span>
                        <ul>
                          <li>
                            <a
                              href={guest.twitter}
                              target="blank"
                              className="twitter"
                            >
                              <i className="icofont-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href={guest.linkedin}
                              target="blank"
                              className="linkedin"
                            >
                              <i className="icofont-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
        <section className="speakers-area ptb-120">
          <div className="container">
            <div className="section-title">
              <h2>CO ORGANIZERS & LEADERS</h2>
              <div className="bar"></div>
            </div>
            <div>
              <SpeakerList />
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default Speakers
