import React from 'react'
import { Link } from 'react-router-dom'
import ModalVideo from 'react-modal-video'
import '../../../src/assets/css/conf-personal.css'
import awsLogoColibri from '../../assets/images/logo.png'
import pucpLogo from '../../assets/images/PUCP-Logo.png'
import awsSmile from '../../assets/images/smile-aws.png'

class MainBanner extends React.Component {
  state = {
    days: '',
    hours: '',
    minutes: '',
    seconds: '',
    isOpen: false
  }

  openModal = () => {
    this.setState({ isOpen: true })
  }

  makeTimer = () => {
    const endTime = new Date('September 20 2024 00:00:00 PDT')
    const endTimeParse = (Date.parse(endTime)) / 1000
    const now = new Date()
    const nowParse = (Date.parse(now) / 1000)
    const timeLeft = endTimeParse - nowParse
    const days = Math.floor(timeLeft / 86400)
    let hours = Math.floor((timeLeft - (days * 86400)) / 3600)
    let minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600)) / 60)
    let seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)))
    if (hours < '10') { hours = '0' + hours }
    if (minutes < '10') { minutes = '0' + minutes }
    if (seconds < '10') { seconds = '0' + seconds }
    this.setState({
      days, hours, minutes, seconds
    })
  }

  componentDidMount () {
    setInterval(() => {
      this.makeTimer()
    }, 1000)
  }

  render () {
    return (
      <React.Fragment>
        <ModalVideo
          channel="youtube"
          isOpen={this.state.isOpen}
          videoId="TE_pxeEGVGQ"
          onClose={() => this.setState({ isOpen: false })}
        />
        <div className="main-banner video-banner">
          <video loop muted autoPlay poster="#" className="video-background">
            <source
              src={require('../../assets/video/test2.mp4')}
              type="video/mp4"
            />
          </video>

          <div className="d-table">
            <div className="d-table-cell" id="head">
              <div className="container">
                <div className="main-banner-content middle">
                  <h1>UGCONF PERU 2024 & SERVERLESSDAYS LIMA</h1>
                  <ul>
                    <li>
                      <i className="icofont-ui-remove"> </i>
                      THIRD EDITION UG PERU CONF
                    </li>
                  </ul>

                  <div className="button-box">
                    <ul>
                      <li>
                        <i className="icofont-location-pin"></i>
                        <a
                          style={{
                            color: 'white',
                            textDecoration: 'underline'
                          }}
                          rel="noopener noreferrer"
                          target="_blank"
                          href="https://www.google.com/maps/place/Open+PUCP/@-12.0764749,-77.0835361,17z/data=!3m1!4b1!4m6!3m5!1s0x9105c9ae8fab853d:0x2fa3c1e66fd5e70c!8m2!3d-12.0764802!4d-77.0809612!16s%2Fg%2F11fp3xn5bq?hl=en-PE&entry=ttu"
                        >
                          OPEN PUCP Blvd. Plaza Mantaro 102, San Miguel 15088
                        </a>
                      </li>
                      <li>
                        <i className="icofont-compass"></i> Lima, Perú
                      </li>
                      <li>
                        <i className="icofont-calendar padding-right"></i> 20
                        Sep, 2024
                      </li>
                    </ul>
                    <Link
                      onClick={(e) => {
                        e.preventDefault()
                        this.openModal()
                      }}
                      to="#"
                      className="video-btn popup-youtube"
                    >
                      <i className="icofont-ui-play"></i>Welcome
                      Video from our special Guests and Jeff Barr
                    </Link>
                  </div>
                  <Link
                    target="_blank"
                    to="agenda"
                    className="btn btn-primary changes"
                  >
                    VER AGENDA COMPLETA
                  </Link>
                  <div className="logos-edit">
                    <h2 className="especial-title">Organizado por ...</h2>
                    <div className="logos-container">
                      <img src={pucpLogo} />
                      <img src={awsLogoColibri} />
                      <img src={awsSmile} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="event-countdown">
            <div id="timer">
              <div id="days">
                {this.state.days} <span>Days</span>
              </div>
              <div id="hours">
                {this.state.hours} <span>Hours</span>
              </div>
              <div id="minutes">
                {this.state.minutes} <span>Minutes</span>
              </div>
              <div id="seconds">
                {this.state.seconds} <span>Seconds</span>
              </div>
            </div>
          </div>

          <div className="shape1">
            <img
              src={require('../../assets/images/shapes/1.png')}
              alt="shape1"
            />
          </div>
          <div className="shape2 rotateme">
            <img
              src={require('../../assets/images/shapes/2.png')}
              alt="shape2"
            />
          </div>
          <div className="shape3 rotateme">
            <img
              src={require('../../assets/images/shapes/3.png')}
              alt="shape3"
            />
          </div>
          <div className="shape4">
            <img
              src={require('../../assets/images/shapes/4.png')}
              alt="shape4"
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default MainBanner
